import { Link } from "react-router-dom";
import logo from "../../assets/images/logoSms.png";
import { addLinkToButton } from "../../telegram/telegram";

const Sms = () => {
  return (
    <div className="sms__wrapper">
      <img
        src={logo}
        alt="logotype"
        width={185}
        height={57}
        className="sms__logo"
      />

      <p className="sms__title fade">Тисни на кнопки</p>
      <p className="sms__description">та переходь у наші соцмережі:</p>
      <Link to="/" className="svg__wrapper">
        <svg
          className="sms__button"
          viewBox="0 0 387 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="387"
            height="72"
            rx="12"
            fill="url(#paint0_linear_1365_149)"
          />
          <rect
            x="1.5"
            y="1.5"
            width="384"
            height="69"
            rx="10.5"
            stroke="white"
            strokeOpacity="0.35"
            strokeWidth="3"
            className="border"
          />
          <path
            d="M271.735 30.5312V33.0668H265.158V45.0767H262.083V30.5312H271.735Z"
            fill="#262932"
          />
          <path
            d="M259.805 37.804C259.805 39.3902 259.504 40.7396 258.903 41.8523C258.306 42.965 257.492 43.8149 256.46 44.402C255.432 44.9844 254.277 45.2756 252.994 45.2756C251.701 45.2756 250.541 44.982 249.514 44.3949C248.486 43.8078 247.674 42.9579 247.077 41.8452C246.481 40.7325 246.183 39.3854 246.183 37.804C246.183 36.2178 246.481 34.8684 247.077 33.7557C247.674 32.643 248.486 31.7955 249.514 31.2131C250.541 30.626 251.701 30.3324 252.994 30.3324C254.277 30.3324 255.432 30.626 256.46 31.2131C257.492 31.7955 258.306 32.643 258.903 33.7557C259.504 34.8684 259.805 36.2178 259.805 37.804ZM256.687 37.804C256.687 36.7765 256.533 35.91 256.225 35.2046C255.922 34.4991 255.494 33.964 254.94 33.5994C254.386 33.2349 253.737 33.0526 252.994 33.0526C252.25 33.0526 251.602 33.2349 251.048 33.5994C250.494 33.964 250.063 34.4991 249.755 35.2046C249.452 35.91 249.301 36.7765 249.301 37.804C249.301 38.8315 249.452 39.6979 249.755 40.4034C250.063 41.1089 250.494 41.644 251.048 42.0085C251.602 42.3731 252.25 42.5554 252.994 42.5554C253.737 42.5554 254.386 42.3731 254.94 42.0085C255.494 41.644 255.922 41.1089 256.225 40.4034C256.533 39.6979 256.687 38.8315 256.687 37.804Z"
            fill="#262932"
          />
          <path
            d="M231.041 45.0767V42.6051L231.453 42.5696C231.983 42.527 232.412 42.3258 232.738 41.9659C233.065 41.6013 233.309 41.0213 233.47 40.2259C233.636 39.4257 233.742 38.3509 233.79 37.0014L234.031 30.5312H243.903V45.0767H240.913V32.9957H236.886L236.659 38.0597C236.583 39.6884 236.389 41.0189 236.077 42.0511C235.769 43.0833 235.276 43.8456 234.599 44.3381C233.927 44.8305 233.006 45.0767 231.836 45.0767H231.041Z"
            fill="#262932"
          />
          <path
            d="M219.376 45.0767H216.08L221.101 30.5312H225.064L230.079 45.0767H226.783L223.14 33.8551H223.026L219.376 45.0767ZM219.17 39.3594H226.954V41.7599H219.17V39.3594Z"
            fill="#262932"
          />
          <path
            d="M204.732 33.0668V30.5312H216.678V33.0668H212.225V45.0767H209.186V33.0668H204.732Z"
            fill="#262932"
          />
          <path
            d="M194.61 45.0767H191.314L196.336 30.5312H200.299L205.313 45.0767H202.018L198.374 33.8551H198.26L194.61 45.0767ZM194.404 39.3594H202.188V41.7599H194.404V39.3594Z"
            fill="#262932"
          />
          <path
            d="M186.742 45.0767L182.069 38.8764H181.018V45.0767H177.942V30.5312H181.018V36.3409H181.636L186.465 30.5312H190.293L184.619 37.2784L190.556 45.0767H186.742Z"
            fill="#262932"
          />
          <path
            d="M159.4 33.0668V30.5312H171.346V33.0668H166.893V45.0767H163.854V33.0668H159.4Z"
            fill="#262932"
          />
          <path
            d="M145.247 30.5313H148.315V39.679H148.436L154.722 30.5313H157.413V45.0768H154.338V35.9219H154.231L147.91 45.0768H145.247V30.5313ZM152.747 26.7245H154.856C154.852 27.5862 154.532 28.2799 153.898 28.8055C153.268 29.331 152.416 29.5938 151.341 29.5938C150.261 29.5938 149.407 29.331 148.777 28.8055C148.147 28.2799 147.832 27.5862 147.832 26.7245H149.927C149.923 27.0512 150.027 27.3448 150.24 27.6052C150.458 27.8656 150.825 27.9958 151.341 27.9958C151.843 27.9958 152.203 27.868 152.42 27.6123C152.638 27.3566 152.747 27.0607 152.747 26.7245Z"
            fill="#262932"
          />
          <path
            d="M132.794 45.0767H129.498L134.519 30.5312H138.482L143.497 45.0767H140.201L136.558 33.8551H136.444L132.794 45.0767ZM132.588 39.3594H140.372V41.7599H132.588V39.3594Z"
            fill="#262932"
          />
          <path
            d="M128.354 35.6236H125.243C125.187 35.2211 125.071 34.8636 124.895 34.5511C124.72 34.2339 124.495 33.964 124.221 33.7415C123.946 33.519 123.629 33.3485 123.269 33.2301C122.914 33.1118 122.528 33.0526 122.111 33.0526C121.358 33.0526 120.703 33.2396 120.144 33.6136C119.585 33.983 119.152 34.5227 118.844 35.233C118.536 35.9385 118.383 36.7955 118.383 37.804C118.383 38.8409 118.536 39.7121 118.844 40.4176C119.157 41.1231 119.592 41.6558 120.151 42.0156C120.71 42.3755 121.356 42.5554 122.09 42.5554C122.502 42.5554 122.883 42.501 123.233 42.3921C123.589 42.2832 123.903 42.1245 124.178 41.9162C124.453 41.7031 124.68 41.4451 124.86 41.1421C125.044 40.839 125.172 40.4934 125.243 40.1051L128.354 40.1193C128.274 40.7869 128.072 41.4309 127.75 42.0511C127.433 42.6667 127.005 43.2183 126.465 43.706C125.93 44.1889 125.291 44.5725 124.547 44.8565C123.809 45.1359 122.973 45.2756 122.04 45.2756C120.743 45.2756 119.583 44.982 118.56 44.3949C117.542 43.8078 116.737 42.9579 116.145 41.8452C115.558 40.7325 115.265 39.3854 115.265 37.804C115.265 36.2178 115.563 34.8684 116.16 33.7557C116.756 32.643 117.566 31.7955 118.589 31.2131C119.611 30.626 120.762 30.3324 122.04 30.3324C122.883 30.3324 123.664 30.4508 124.384 30.6875C125.108 30.9243 125.75 31.2699 126.309 31.7244C126.867 32.1743 127.322 32.7259 127.672 33.3793C128.027 34.0327 128.255 34.7808 128.354 35.6236Z"
            fill="#262932"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1365_149"
              x1="198.215"
              y1="82.2825"
              x2="193.16"
              y2="-10.5208"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.345" stopColor="#FCB25F" />
              <stop offset="1" stopColor="#FDE7CE" />
            </linearGradient>
          </defs>
        </svg>
        <div className="sms__addictional">
          <p className="sms__addictional--text">
            У каталозі ви можете переглянути та замовити усі наявні моделі
          </p>
        </div>
      </Link>
      <div
        className="svg__wrapper"
        style={{ marginBottom: "15px" }}
        onClick={() => {
          addLinkToButton();
        }}
      >
        <div className="sms__icon">
          <svg
            className="sms__button"
            viewBox="0 0 387 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="387"
              height="72"
              rx="12"
              fill="url(#paint0_linear_1365_163)"
            />
            <rect
              x="1.5"
              y="1.5"
              width="384"
              height="69"
              rx="10.5"
              stroke="white"
              strokeOpacity="0.35"
              strokeWidth="3"
              className="border"
            />
            <path
              d="M120.706 23.4828C120.222 28.5773 118.122 40.9589 117.056 46.666C116.604 49.0843 115.7 49.8904 114.86 49.9871C112.986 50.1484 111.565 48.7619 109.756 47.5689C106.913 45.6987 105.298 44.538 102.553 42.7323C99.3547 40.6365 101.422 39.4757 103.263 37.6055C103.748 37.1219 112.017 29.6091 112.179 28.932C112.201 28.8294 112.198 28.7229 112.17 28.6218C112.142 28.5206 112.089 28.4279 112.017 28.3516C111.823 28.1904 111.565 28.2548 111.339 28.2871C111.048 28.3516 106.526 31.3502 97.7073 37.2831C96.4152 38.1537 95.2524 38.6051 94.2187 38.5729C93.0558 38.5406 90.8593 37.928 89.2118 37.3798C87.1768 36.735 85.594 36.3803 85.7232 35.2517C85.7878 34.6714 86.5954 34.091 88.1136 33.4783C97.5458 29.3834 103.812 26.6749 106.946 25.3852C115.926 21.6449 117.767 21 118.994 21C119.253 21 119.867 21.0645 120.254 21.3869C120.577 21.6449 120.674 21.9996 120.706 22.2575C120.674 22.451 120.739 23.0314 120.706 23.4828Z"
              fill="white"
            />
            <path
              d="M290.017 28.7273H293.809L297.815 38.5H297.985L301.991 28.7273H305.784V43.2727H302.801V33.8054H302.68L298.916 43.2017H296.884L293.12 33.7699H293V43.2727H290.017V28.7273Z"
              fill="white"
            />
            <path
              d="M277.563 43.2727H274.268L279.289 28.7273H283.252L288.266 43.2727H284.971L281.327 32.0512H281.214L277.563 43.2727ZM277.357 37.5554H285.141V39.956H277.357V37.5554Z"
              fill="white"
            />
            <path
              d="M263.786 43.2727V28.7273H269.525C270.628 28.7273 271.568 28.938 272.344 29.3594C273.121 29.7761 273.713 30.3561 274.12 31.0995C274.532 31.8381 274.738 32.6904 274.738 33.6563C274.738 34.6222 274.53 35.4745 274.113 36.2131C273.696 36.9517 273.092 37.527 272.302 37.9389C271.516 38.3509 270.564 38.5568 269.447 38.5568H265.789V36.0924H268.949C269.541 36.0924 270.029 35.9906 270.413 35.787C270.801 35.5786 271.09 35.2922 271.279 34.9276C271.473 34.5583 271.57 34.1345 271.57 33.6563C271.57 33.1733 271.473 32.7519 271.279 32.3921C271.09 32.0275 270.801 31.7458 270.413 31.5469C270.024 31.3433 269.532 31.2415 268.935 31.2415H266.861V43.2727H263.786Z"
              fill="white"
            />
            <path
              d="M261.817 28.7273V31.2628H255.24V43.2727H252.165V28.7273H261.817Z"
              fill="white"
            />
            <path
              d="M239.919 43.2727V28.7273H249.72V31.2628H242.994V34.7287H249.216V37.2642H242.994V40.7372H249.748V43.2727H239.919Z"
              fill="white"
            />
            <path
              d="M224.521 43.2727V40.8012L224.933 40.7656C225.464 40.723 225.892 40.5218 226.219 40.162C226.546 39.7974 226.789 39.2174 226.95 38.4219C227.116 37.6217 227.223 36.5469 227.27 35.1975L227.512 28.7273H237.384V43.2727H234.394V31.1918H230.367L230.139 36.2557C230.064 37.8845 229.869 39.215 229.557 40.2472C229.249 41.2794 228.757 42.0417 228.08 42.5341C227.407 43.0265 226.486 43.2727 225.317 43.2727H224.521Z"
              fill="white"
            />
            <path
              d="M213.063 43.2727V28.7273H222.865V31.2628H216.139V34.7287H222.36V37.2642H216.139V40.7372H222.893V43.2727H213.063Z"
              fill="white"
            />
            <path
              d="M199.15 31.2628V28.7273H211.096V31.2628H206.643V43.2727H203.604V31.2628H199.15Z"
              fill="white"
            />
            <path
              d="M174.681 28.7273H177.685V40.8012H181.861V28.7273H184.851V40.8012H188.978V28.7273H191.968V43.2727H174.681V28.7273Z"
              fill="white"
            />
            <path
              d="M161.645 43.2727H158.35L163.371 28.7273H167.334L172.348 43.2727H169.053L165.409 32.0512H165.296L161.645 43.2727ZM161.439 37.5554H169.223V39.956H161.439V37.5554Z"
              fill="white"
            />
            <path
              d="M144.216 43.2727V28.7273H147.291V34.7287H153.534V28.7273H156.602V43.2727H153.534V37.2642H147.291V43.2727H144.216Z"
              fill="white"
            />
            <defs>
              <linearGradient
                id="paint0_linear_1365_163"
                x1="0"
                y1="0"
                x2="386.481"
                y2="74.6846"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#2BCCFF" />
                <stop offset="1" stopColor="#2878F1" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className="sms__addictional--two">
          <p className="sms__addictional--description">
            Даруємо подарунковий сертифікат та промокод на
            <span className="sms__addictional--accent"> Безкоштовну</span>{" "}
            доставку при підписці сьогодні
          </p>
        </div>
      </div>
      <div
        className="sms__button--wrapper"
        style={{ marginBottom: "10px" }}
        onClick={() => {
          window.location.href = "https://www.instagram.com/princess.wigs/";
        }}
      >
        <svg
          className="sms__button"
          viewBox="0 0 387 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="387"
            height="72"
            rx="12"
            fill="url(#paint0_linear_1365_180)"
          />
          <rect
            width="387"
            height="72"
            rx="12"
            fill="white"
            fillOpacity="0.2"
          />
          <rect
            x="1.5"
            y="1.5"
            width="384"
            height="69"
            rx="10.5"
            stroke="white"
            strokeOpacity="0.35"
            strokeWidth="3"
            className="border"
          />
          <path
            d="M91.0166 17.25H106.767C112.767 17.25 117.642 22.125 117.642 28.125V43.875C117.642 46.7592 116.496 49.5253 114.456 51.5648C112.417 53.6042 109.651 54.75 106.767 54.75H91.0166C85.0166 54.75 80.1416 49.875 80.1416 43.875V28.125C80.1416 25.2408 81.2874 22.4747 83.3268 20.4352C85.3663 18.3958 88.1324 17.25 91.0166 17.25ZM90.6416 21C88.8514 21 87.1345 21.7112 85.8686 22.977C84.6028 24.2429 83.8916 25.9598 83.8916 27.75V44.25C83.8916 47.9812 86.9104 51 90.6416 51H107.142C108.932 51 110.649 50.2888 111.915 49.023C113.18 47.7571 113.892 46.0402 113.892 44.25V27.75C113.892 24.0187 110.873 21 107.142 21H90.6416ZM108.735 23.8125C109.357 23.8125 109.953 24.0594 110.393 24.499C110.832 24.9385 111.079 25.5346 111.079 26.1562C111.079 26.7779 110.832 27.374 110.393 27.8135C109.953 28.2531 109.357 28.5 108.735 28.5C108.114 28.5 107.518 28.2531 107.078 27.8135C106.639 27.374 106.392 26.7779 106.392 26.1562C106.392 25.5346 106.639 24.9385 107.078 24.499C107.518 24.0594 108.114 23.8125 108.735 23.8125ZM98.8916 26.625C101.378 26.625 103.763 27.6127 105.521 29.3709C107.279 31.129 108.267 33.5136 108.267 36C108.267 38.4864 107.279 40.871 105.521 42.6291C103.763 44.3873 101.378 45.375 98.8916 45.375C96.4052 45.375 94.0206 44.3873 92.2625 42.6291C90.5043 40.871 89.5166 38.4864 89.5166 36C89.5166 33.5136 90.5043 31.129 92.2625 29.3709C94.0206 27.6127 96.4052 26.625 98.8916 26.625ZM98.8916 30.375C97.3998 30.375 95.969 30.9676 94.9141 32.0225C93.8592 33.0774 93.2666 34.5082 93.2666 36C93.2666 37.4918 93.8592 38.9226 94.9141 39.9775C95.969 41.0324 97.3998 41.625 98.8916 41.625C100.383 41.625 101.814 41.0324 102.869 39.9775C103.924 38.9226 104.517 37.4918 104.517 36C104.517 34.5082 103.924 33.0774 102.869 32.0225C101.814 30.9676 100.383 30.375 98.8916 30.375Z"
            fill="white"
          />
          <path
            d="M294.841 28.7273H298.633L302.639 38.5H302.81L306.815 28.7273H310.608V43.2727H307.625V33.8054H307.504L303.74 43.2017H301.709L297.945 33.7699H297.824V43.2727H294.841V28.7273Z"
            fill="white"
          />
          <path
            d="M282.387 43.2727H279.092L284.113 28.7273H288.076L293.09 43.2727H289.795L286.151 32.0512H286.038L282.387 43.2727ZM282.181 37.5554H289.965V39.956H282.181V37.5554Z"
            fill="white"
          />
          <path
            d="M268.61 43.2727V28.7273H274.349C275.452 28.7273 276.392 28.938 277.169 29.3594C277.945 29.7761 278.537 30.3561 278.944 31.0995C279.356 31.8381 279.562 32.6904 279.562 33.6563C279.562 34.6222 279.354 35.4745 278.937 36.2131C278.52 36.9517 277.917 37.527 277.126 37.9389C276.34 38.3509 275.388 38.5568 274.271 38.5568H270.613V36.0924H273.774C274.366 36.0924 274.853 35.9906 275.237 35.787C275.625 35.5786 275.914 35.2922 276.103 34.9276C276.297 34.5583 276.394 34.1345 276.394 33.6563C276.394 33.1733 276.297 32.7519 276.103 32.3921C275.914 32.0275 275.625 31.7458 275.237 31.5469C274.849 31.3433 274.356 31.2415 273.759 31.2415H271.686V43.2727H268.61Z"
            fill="white"
          />
          <path
            d="M266.641 28.7273V31.2628H260.065V43.2727H256.989V28.7273H266.641Z"
            fill="white"
          />
          <path
            d="M244.536 43.2727H241.24L246.262 28.7273H250.225L255.239 43.2727H251.943L248.3 32.0512H248.186L244.536 43.2727ZM244.33 37.5554H252.114V39.956H244.33V37.5554Z"
            fill="white"
          />
          <path
            d="M229.893 31.2628V28.7273H241.839V31.2628H237.385V43.2727H234.346V31.2628H229.893Z"
            fill="white"
          />
          <path
            d="M228.241 33.8196H225.13C225.073 33.4172 224.957 33.0597 224.782 32.7472C224.607 32.43 224.382 32.1601 224.107 31.9375C223.833 31.715 223.515 31.5445 223.156 31.4262C222.801 31.3078 222.415 31.2486 221.998 31.2486C221.245 31.2486 220.589 31.4356 220.031 31.8097C219.472 32.179 219.039 32.7188 218.731 33.429C218.423 34.1345 218.269 34.9915 218.269 36C218.269 37.037 218.423 37.9082 218.731 38.6137C219.043 39.3192 219.479 39.8518 220.038 40.2117C220.596 40.5715 221.243 40.7515 221.977 40.7515C222.389 40.7515 222.77 40.697 223.12 40.5881C223.475 40.4792 223.79 40.3206 224.065 40.1122C224.339 39.8992 224.567 39.6411 224.747 39.3381C224.931 39.0351 225.059 38.6894 225.13 38.3012L228.241 38.3154C228.16 38.983 227.959 39.6269 227.637 40.2472C227.32 40.8627 226.891 41.4143 226.352 41.902C225.817 42.385 225.177 42.7685 224.434 43.0526C223.695 43.3319 222.86 43.4716 221.927 43.4716C220.63 43.4716 219.47 43.1781 218.447 42.5909C217.429 42.0038 216.624 41.1539 216.032 40.0412C215.445 38.9285 215.151 37.5815 215.151 36C215.151 34.4139 215.45 33.0644 216.046 31.9517C216.643 30.839 217.453 29.9915 218.475 29.4091C219.498 28.822 220.649 28.5284 221.927 28.5284C222.77 28.5284 223.551 28.6468 224.271 28.8836C224.995 29.1203 225.637 29.4659 226.195 29.9205C226.754 30.3703 227.209 30.9219 227.559 31.5753C227.914 32.2287 228.141 32.9768 228.241 33.8196Z"
            fill="white"
          />
          <path
            d="M200.485 43.2727V28.7273H203.561V34.7287H209.804V28.7273H212.872V43.2727H209.804V37.2642H203.561V43.2727H200.485Z"
            fill="white"
          />
          <path
            d="M197.955 28.7273V43.2727H194.88V28.7273H197.955Z"
            fill="white"
          />
          <path
            d="M169.856 28.7273H172.861V40.8012H177.037V28.7273H180.027V40.8012H184.153V28.7273H187.143V43.2727H169.856V28.7273Z"
            fill="white"
          />
          <path
            d="M156.821 43.2727H153.525L158.547 28.7273H162.51L167.524 43.2727H164.229L160.585 32.0512H160.471L156.821 43.2727ZM156.615 37.5554H164.399V39.956H156.615V37.5554Z"
            fill="white"
          />
          <path
            d="M139.392 43.2727V28.7273H142.467V34.7287H148.71V28.7273H151.778V43.2727H148.71V37.2642H142.467V43.2727H139.392Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1365_180"
              x1="0"
              y1="36.6316"
              x2="387"
              y2="36.6316"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#042096" />
              <stop offset="0.315693" stopColor="#B40080" />
              <stop offset="0.627181" stopColor="#FC4248" />
              <stop offset="1" stopColor="#F7B400" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div
        className="sms__button--wrapper"
        onClick={() => {
          window.location.href = "https://www.facebook.com/princesswigsstore";
        }}
      >
        <svg
          className="sms__button"
          viewBox="0 0 387 72"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            width="387"
            height="72"
            rx="12"
            fill="url(#paint0_linear_1365_197)"
          />
          <rect
            x="1.5"
            y="1.5"
            width="384"
            height="69"
            rx="10.5"
            stroke="white"
            strokeOpacity="0.35"
            strokeWidth="3"
            className="border"
          />
          <path
            d="M98.7627 36C98.7627 25.65 90.3627 17.25 80.0127 17.25C69.6627 17.25 61.2627 25.65 61.2627 36C61.2627 45.075 67.7127 52.6312 76.2627 54.375V41.625H72.5127V36H76.2627V31.3125C76.2627 27.6937 79.2064 24.75 82.8252 24.75H87.5127V30.375H83.7627C82.7314 30.375 81.8877 31.2187 81.8877 32.25V36H87.5127V41.625H81.8877V54.6562C91.3564 53.7188 98.7627 45.7312 98.7627 36Z"
            fill="white"
          />
          <path
            d="M318.784 44.8777H315.488L320.51 30.3323H324.473L329.487 44.8777H326.191L322.548 33.6561H322.434L318.784 44.8777ZM318.578 39.1604H326.362V41.561H318.578V39.1604Z"
            fill="white"
          />
          <path
            d="M310.916 44.8777L306.243 38.6774H305.191V44.8777H302.116V30.3323H305.191V36.1419H305.809L310.639 30.3323H314.467L308.792 37.0794L314.73 44.8777H310.916Z"
            fill="white"
          />
          <path
            d="M287.194 44.8777V30.3323H290.27V36.3337H296.513V30.3323H299.581V44.8777H296.513V38.8692H290.27V44.8777H287.194Z"
            fill="white"
          />
          <path
            d="M284.664 30.3323V44.8777H281.589V30.3323H284.664Z"
            fill="white"
          />
          <path
            d="M268.64 44.8777V30.3323H274.378C275.482 30.3323 276.421 30.543 277.198 30.9644C277.974 31.381 278.566 31.9611 278.973 32.7044C279.385 33.4431 279.591 34.2953 279.591 35.2613C279.591 36.2272 279.383 37.0794 278.966 37.8181C278.55 38.5567 277.946 39.132 277.155 39.5439C276.369 39.9559 275.418 40.1618 274.3 40.1618H270.642V37.6973H273.803C274.395 37.6973 274.883 37.5955 275.266 37.3919C275.654 37.1836 275.943 36.8971 276.133 36.5326C276.327 36.1632 276.424 35.7395 276.424 35.2613C276.424 34.7783 276.327 34.3569 276.133 33.997C275.943 33.6325 275.654 33.3507 275.266 33.1519C274.878 32.9483 274.385 32.8465 273.789 32.8465H271.715V44.8777H268.64Z"
            fill="white"
          />
          <path
            d="M266.361 37.605C266.361 39.1912 266.061 40.5406 265.459 41.6533C264.863 42.766 264.048 43.6159 263.016 44.203C261.989 44.7854 260.833 45.0766 259.55 45.0766C258.258 45.0766 257.098 44.783 256.07 44.1959C255.043 43.6088 254.231 42.7589 253.634 41.6462C253.038 40.5335 252.739 39.1865 252.739 37.605C252.739 36.0188 253.038 34.6694 253.634 33.5567C254.231 32.444 255.043 31.5965 256.07 31.0141C257.098 30.427 258.258 30.1334 259.55 30.1334C260.833 30.1334 261.989 30.427 263.016 31.0141C264.048 31.5965 264.863 32.444 265.459 33.5567C266.061 34.6694 266.361 36.0188 266.361 37.605ZM263.244 37.605C263.244 36.5776 263.09 35.7111 262.782 35.0056C262.479 34.3001 262.05 33.7651 261.496 33.4005C260.942 33.0359 260.294 32.8536 259.55 32.8536C258.807 32.8536 258.158 33.0359 257.604 33.4005C257.05 33.7651 256.619 34.3001 256.312 35.0056C256.009 35.7111 255.857 36.5776 255.857 37.605C255.857 38.6325 256.009 39.499 256.312 40.2044C256.619 40.9099 257.05 41.445 257.604 41.8096C258.158 42.1741 258.807 42.3564 259.55 42.3564C260.294 42.3564 260.942 42.1741 261.496 41.8096C262.05 41.445 262.479 40.9099 262.782 40.2044C263.09 39.499 263.244 38.6325 263.244 37.605Z"
            fill="white"
          />
          <path
            d="M239.746 32.8678V30.3323H251.692V32.8678H247.239V44.8777H244.199V32.8678H239.746Z"
            fill="white"
          />
          <path
            d="M238.094 35.4246H234.984C234.927 35.0222 234.811 34.6647 234.636 34.3522C234.46 34.0349 234.235 33.7651 233.961 33.5425C233.686 33.32 233.369 33.1495 233.009 33.0312C232.654 32.9128 232.268 32.8536 231.851 32.8536C231.099 32.8536 230.443 33.0406 229.884 33.4147C229.325 33.784 228.892 34.3238 228.584 35.034C228.277 35.7395 228.123 36.5965 228.123 37.605C228.123 38.6419 228.277 39.5132 228.584 40.2187C228.897 40.9241 229.333 41.4568 229.891 41.8167C230.45 42.1765 231.096 42.3564 231.83 42.3564C232.242 42.3564 232.623 42.302 232.974 42.1931C233.329 42.0842 233.644 41.9256 233.918 41.7172C234.193 41.5042 234.42 41.2461 234.6 40.9431C234.785 40.6401 234.913 40.2944 234.984 39.9062L238.094 39.9204C238.014 40.588 237.813 41.2319 237.491 41.8522C237.173 42.4677 236.745 43.0193 236.205 43.507C235.67 43.99 235.031 44.3735 234.288 44.6576C233.549 44.9369 232.713 45.0766 231.78 45.0766C230.483 45.0766 229.323 44.783 228.3 44.1959C227.282 43.6088 226.477 42.7589 225.886 41.6462C225.298 40.5335 225.005 39.1865 225.005 37.605C225.005 36.0188 225.303 34.6694 225.9 33.5567C226.496 32.444 227.306 31.5965 228.329 31.0141C229.351 30.427 230.502 30.1334 231.78 30.1334C232.623 30.1334 233.405 30.2518 234.124 30.4885C234.849 30.7253 235.49 31.0709 236.049 31.5255C236.608 31.9753 237.062 32.5269 237.413 33.1803C237.768 33.8337 237.995 34.5818 238.094 35.4246Z"
            fill="white"
          />
          <path
            d="M215.271 44.8777L210.598 38.6774H209.547V44.8777H206.472V30.3323H209.547V36.1419H210.165L214.994 30.3323H218.823L213.148 37.0794L219.085 44.8777H215.271Z"
            fill="white"
          />
          <path
            d="M194.623 45.0766V42.5766H195.411C195.804 42.5766 196.122 42.5292 196.363 42.4345C196.605 42.3351 196.796 42.1907 196.938 42.0013C197.085 41.8072 197.208 41.5657 197.308 41.2769L197.485 40.801L192.201 30.3323H195.383L198.806 37.4914L201.669 30.3323H204.808L199.794 42.0226C199.552 42.5624 199.266 43.0643 198.934 43.5283C198.607 43.9923 198.188 44.3664 197.677 44.6505C197.166 44.9345 196.512 45.0766 195.717 45.0766H194.623Z"
            fill="white"
          />
          <path
            d="M180.026 44.8777V30.3323H189.678V32.8678H183.102V35.7087H185.467C186.617 35.7087 187.609 35.8957 188.443 36.2698C189.281 36.6391 189.927 37.1623 190.381 37.8394C190.836 38.5165 191.063 39.3143 191.063 40.2328C191.063 41.1467 190.836 41.954 190.381 42.6547C189.927 43.3507 189.281 43.8952 188.443 44.2882C187.609 44.6812 186.617 44.8777 185.467 44.8777H180.026ZM183.102 42.4061H185.467C186.016 42.4061 186.48 42.3067 186.859 42.1078C187.238 41.9042 187.524 41.6367 187.718 41.3053C187.917 40.9691 188.016 40.6022 188.016 40.2044C188.016 39.6268 187.796 39.1391 187.356 38.7414C186.92 38.3389 186.291 38.1377 185.467 38.1377H183.102V42.4061Z"
            fill="white"
          />
          <path
            d="M177.821 35.4246H174.71C174.653 35.0222 174.537 34.6647 174.362 34.3522C174.187 34.0349 173.962 33.7651 173.687 33.5425C173.413 33.32 173.096 33.1495 172.736 33.0312C172.381 32.9128 171.995 32.8536 171.578 32.8536C170.825 32.8536 170.169 33.0406 169.611 33.4147C169.052 33.784 168.619 34.3238 168.311 35.034C168.003 35.7395 167.849 36.5965 167.849 37.605C167.849 38.6419 168.003 39.5132 168.311 40.2187C168.623 40.9241 169.059 41.4568 169.618 41.8167C170.177 42.1765 170.823 42.3564 171.557 42.3564C171.969 42.3564 172.35 42.302 172.7 42.1931C173.055 42.0842 173.37 41.9256 173.645 41.7172C173.919 41.5042 174.147 41.2461 174.327 40.9431C174.511 40.6401 174.639 40.2944 174.71 39.9062L177.821 39.9204C177.74 40.588 177.539 41.2319 177.217 41.8522C176.9 42.4677 176.472 43.0193 175.932 43.507C175.397 43.99 174.757 44.3735 174.014 44.6576C173.275 44.9369 172.44 45.0766 171.507 45.0766C170.21 45.0766 169.05 44.783 168.027 44.1959C167.009 43.6088 166.204 42.7589 165.612 41.6462C165.025 40.5335 164.731 39.1865 164.731 37.605C164.731 36.0188 165.03 34.6694 165.626 33.5567C166.223 32.444 167.033 31.5965 168.055 31.0141C169.078 30.427 170.229 30.1334 171.507 30.1334C172.35 30.1334 173.131 30.2518 173.851 30.4885C174.575 30.7253 175.217 31.0709 175.775 31.5255C176.334 31.9753 176.789 32.5269 177.139 33.1803C177.494 33.8337 177.722 34.5818 177.821 35.4246Z"
            fill="white"
          />
          <path
            d="M150.28 30.3323H153.348V39.4801H153.469L159.755 30.3323H162.446V44.8778H159.371V35.723H159.265L152.944 44.8778H150.28V30.3323ZM157.78 26.5255H159.89C159.885 27.3873 159.565 28.0809 158.931 28.6065C158.301 29.132 157.449 29.3948 156.374 29.3948C155.294 29.3948 154.44 29.132 153.81 28.6065C153.18 28.0809 152.865 27.3873 152.865 26.5255H154.961C154.956 26.8522 155.06 27.1458 155.273 27.4062C155.491 27.6666 155.858 27.7968 156.374 27.7968C156.876 27.7968 157.236 27.669 157.454 27.4133C157.671 27.1576 157.78 26.8617 157.78 26.5255Z"
            fill="white"
          />
          <path
            d="M138.034 44.8777V30.3323H147.835V32.8678H141.109V36.3337H147.331V38.8692H141.109V42.3422H147.864V44.8777H138.034Z"
            fill="white"
          />
          <path
            d="M126.521 31.8948H129.774C130.939 31.8948 131.971 32.1411 132.871 32.6335C133.775 33.1259 134.483 33.8196 134.994 34.7144C135.51 35.6046 135.768 36.651 135.768 37.8536C135.768 39.0184 135.51 40.034 134.994 40.9005C134.483 41.767 133.775 42.4417 132.871 42.9247C131.971 43.4029 130.939 43.642 129.774 43.642H126.521C125.352 43.642 124.315 43.4053 123.41 42.9318C122.506 42.4536 121.796 41.7812 121.28 40.9147C120.768 40.0483 120.513 39.0255 120.513 37.8465C120.513 36.6439 120.771 35.5975 121.287 34.7073C121.808 33.8125 122.52 33.1212 123.425 32.6335C124.329 32.1411 125.361 31.8948 126.521 31.8948ZM126.521 34.4659C125.896 34.4659 125.345 34.5913 124.866 34.8423C124.393 35.0885 124.024 35.4625 123.758 35.9644C123.493 36.4663 123.361 37.0937 123.361 37.8465C123.361 38.5662 123.496 39.1652 123.766 39.6434C124.04 40.1216 124.414 40.4791 124.888 40.7159C125.361 40.9526 125.906 41.071 126.521 41.071H129.795C130.416 41.071 130.958 40.9502 131.422 40.7088C131.891 40.4673 132.253 40.1074 132.508 39.6292C132.769 39.151 132.899 38.5591 132.899 37.8536C132.899 37.1008 132.769 36.4734 132.508 35.9715C132.253 35.4697 131.891 35.0932 131.422 34.8423C130.958 34.5913 130.416 34.4659 129.795 34.4659H126.521ZM129.604 30.0767V45.4744H126.685V30.0767H129.604Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1365_197"
              x1="0"
              y1="0"
              x2="386.481"
              y2="74.6846"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#2651AF" />
              <stop offset="1" stopColor="#2170EF" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default Sms;
