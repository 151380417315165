import { useEffect } from "react";

export const SmsSent = ({ setIsSmsSent }) => {
  const typeOfPrize = localStorage.getItem("wheelPrizeText");

  // useEffect(() => {
  //   if (!localStorage.getItem("alreadyRegisteredInFacebook")) {
  //     localStorage.setItem("alreadyRegisteredInFacebook", true);
  //     let pixel_sms = localStorage.getItem("pixel") ?? "";
  //     // Inject Facebook pixel script
  //     const el = document.getElementById("pixelTagId");
  //     if (el) {
  //       /* global fbq */ // Declare fbq as a global variable
  //       // eslint-disable-next-line
  //       !(function (f, b, e, v, n, t, s) {
  //         if (f.fbq) return;
  //         n = f.fbq = function () {
  //           n.callMethod
  //             ? n.callMethod.apply(n, arguments)
  //             : n.queue.push(arguments);
  //         };
  //         if (!f._fbq) f._fbq = n;
  //         n.push = n;
  //         n.loaded = !0;
  //         n.version = "2.0";
  //         n.queue = [];
  //         t = b.createElement(e);
  //         t.async = !0;
  //         t.src = v;
  //         s = b.getElementsByTagName(e)[0];
  //         s.parentNode.insertBefore(t, s);
  //       })(
  //         window,
  //         document,
  //         "script",
  //         "https://connect.facebook.net/en_US/fbevents.js"
  //       );
  //       fbq("init", pixel_sms);
  //       fbq("track", "CompleteRegistration");
  //     }
  //   }
  // }, []);
  return (
    <div
      className="backdrop"
      id="backdrop"
      onClick={(e) => {
        if (e.target.id === "backdrop") {
          setIsSmsSent(false);
        }
      }}
    >
      <div className="smsSent__modal">
        <svg
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="smsForm__icon"
          onClick={() => {
            setIsSmsSent(false);
          }}
        >
          <path
            d="M16.5 2.75C24.1038 2.75 30.25 8.89625 30.25 16.5C30.25 24.1038 24.1038 30.25 16.5 30.25C8.89625 30.25 2.75 24.1038 2.75 16.5C2.75 8.89625 8.89625 2.75 16.5 2.75ZM21.4363 9.625L16.5 14.5613L11.5637 9.625L9.625 11.5637L14.5613 16.5L9.625 21.4363L11.5637 23.375L16.5 18.4387L21.4363 23.375L23.375 21.4363L18.4387 16.5L23.375 11.5637L21.4363 9.625Z"
            fill="#929292"
          />
        </svg>
        <p className="smsSent__title">Дякуємо</p>
        {typeOfPrize === "1" ? (
          <p className="smsSent__text">
            очікуйте смс з кодом на безкоштовну доставку
          </p>
        ) : (
          <p className="smsSent__text">очікуйте смс з кодом на знижку</p>
        )}
        <div className="smsSent__line"></div>
        <p className="smsSent__info">
          При замовленні повідомте код з смс менеджеру
        </p>
        <button
          type="button"
          className="smsSent__button"
          onClick={() => {
            setIsSmsSent(false);
          }}
        >
          Зрозуміло
        </button>
        <p className="smsSent__description">Бонус оновлюється кожні 3 дні </p>
      </div>
    </div>
  );
};
