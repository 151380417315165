import discount from "../../../assets/images/discount.png";
import minus from "../../../assets/images/minus.png";
import plus from "../../../assets/images/plus.png";
import whiteStars from "../../../assets/images/whiteStars.png";
import spray from "../../../assets/images/spray.png";
import comb from "../../../assets/images/comb.png";

export const Data = ({ cart }) => {
  return (
    <div className="cart__modal">
      <div className="cart__title--wrapper">
        <p className="cart__title">Кошик</p>
        <svg
          className="cart__cross"
          width="24"
          height="24"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 2.75C24.1038 2.75 30.25 8.89625 30.25 16.5C30.25 24.1038 24.1038 30.25 16.5 30.25C8.89625 30.25 2.75 24.1038 2.75 16.5C2.75 8.89625 8.89625 2.75 16.5 2.75ZM21.4363 9.625L16.5 14.5613L11.5637 9.625L9.625 11.5637L14.5613 16.5L9.625 21.4363L11.5637 23.375L16.5 18.4387L21.4363 23.375L23.375 21.4363L18.4387 16.5L23.375 11.5637L21.4363 9.625Z"
            fill="#292D34"
          />
        </svg>
      </div>
      <div className="cart__line"></div>
      <div className="cart__wrapper">
        <img src={discount} alt="discount" width={20} height={20} />
        <p className="cart__text">-20% на кожний наступний товар</p>
      </div>
      <div className="cart__line2"></div>
      <ul className="cart__list">
        {cart.map(({ image, name, price, color, quantity }) => (
          <li className="cart__item">
            <img src={image} alt="model" className="cart__model--image" />
            <div className="cart__model--info">
              <p className="cart__model--name">{name}</p>
              <p className="cart__model--color">Обраний колір: {color}</p>
              <div className="cart__thumb">
                <div className="cart__quantity--wrapper">
                  <img
                    src={minus}
                    alt="Remove one"
                    width={14}
                    height={14}
                    className="cart__model--symbol"
                  />
                  <p className="cart__model--quantity">{quantity}</p>
                  <img
                    src={plus}
                    alt="Add one"
                    width={14}
                    height={14}
                    className="cart__model--symbol"
                  />
                </div>
                <p className="cart__model--price">{price}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="cart__line"></div>
      <p className="cart__recommendations">Рекомендовані товари</p>
      <ul className="cart__recommendations--list">
        <li className="cart__recommendations--item">
          <img src={spray} alt="Спрей" width={45} height={64} />
          <div className="cart__recommendations--wrapper">
            <p className="cart__recommendations--title">
              Спрей для легкого розчісування
            </p>
            <img src={whiteStars} alt="stars" width={70} height={14} />
            <div className="cart__recommendations--thumb">
              <p className="cart__recommendations--price">349 грн</p>
              <button type="button" className="cart__recommendations--button">
                + додати
              </button>
            </div>
          </div>
        </li>
        <li className="cart__recommendations--item">
          <img src={comb} alt="Гребінець" width={45} height={64} />
          <div className="cart__recommendations--wrapper">
            <p className="cart__recommendations--title">
              Гебінець для розчісування
            </p>
            <img
              src={whiteStars}
              alt="stars"
              width={70}
              height={14}
              className="cart__recommendations--stars"
            />
            <div className="cart__recommendations--thumb">
              <p className="cart__recommendations--price">349 грн</p>
              <button type="button" className="cart__recommendations--button">
                + додати
              </button>
            </div>
          </div>
        </li>
      </ul>
      <div className="cart__bottom">
        {/* <img src={wave} alt="wave" className="cart__wave" /> */}
        <div className="cart__bottom--wrapper">
          <p className="cart__bottom--text">Разом</p>
          <p className="cart__bottom--text">1499 грн</p>
        </div>
        <button type="button" className="cart__bottom--button">
          Оформити замовлення
        </button>
        <p className="cart__bottom--info">
          Для{" "}
          <span className="cart__bottom--info cart__bottom--accent">
            безкоштовної доставки
          </span>{" "}
          додайте товарів ще на 0000 грн
        </p>
      </div>
    </div>
  );
};
