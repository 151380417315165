import back from "../../assets/images/wheel-wrap.png";
import wheel from "../../assets/images/wheel.png";
import arrow from "../../assets/images/wheel-arrow.png";
import buttonWrap from "../../assets/images/wheel-button-wrap.png";
import buttonRefresh from "../../assets/images/icon-refresh.png";
import { useState } from "react";

export const Wheel = ({ setIsWheelModalOpen, setIsSmsFormOpen }) => {
  const [rotating, setRotating] = useState(null);
  const onWheelButtonClick = () => {
    if (!rotating) {
      const number = Math.floor(Math.random() * 20) + 1;
      if (number <= 5) {
        localStorage.setItem("wheelPrizeText", "1");
        setRotating(1);
      } else if (number > 5 && number <= 15) {
        localStorage.setItem("wheelPrizeText", "2");
        setRotating(2);
      } else if (number > 15) {
        localStorage.setItem("wheelPrizeText", "3");
        setRotating(3);
      }
      setTimeout(() => {
        setIsWheelModalOpen(false);
        setIsSmsFormOpen(true);
        localStorage.setItem("isWheelAlreadyRunned", true);
      }, 5000);
    }
  };
  return (
    <div
      className="backdrop wheel__backdrop--appearing"
      id="backdrop"
      onClick={(e) => {
        if (rotating === null) {
          if (e.target.id === "backdrop") {
            setIsWheelModalOpen(false);
          }
        }
      }}
    >
      <div className="wheel__wrapper">
        <p className="wheel__title">Крути колесо</p>
        <p className="wheel__text">та забирай свій приз</p>
        <div className="wheel__thumb">
          <img src={back} alt="frame of wheel" className="wheel__back" />
          <img
            src={wheel}
            alt="wheel"
            className={rotating ? `wheel wheel__animation${rotating}` : "wheel"}
          />
          <img src={arrow} alt="wheel arrow" className="wheel__arrow" />
          <img
            src={buttonWrap}
            alt="wheel button"
            className="wheel__button"
            onClick={onWheelButtonClick}
          />
          <img
            src={buttonRefresh}
            alt="wheel button"
            className={
              rotating
                ? "wheel__button--refresh"
                : "wheel__button--refresh wheel__button--animation"
            }
            onClick={onWheelButtonClick}
          />
        </div>
      </div>
    </div>
  );
};
