import slideBack from "../../../assets/images/slideBack.png";
import arrow from "../../../assets/images/arrowUp.png";

export const Contact = () => {
  return (
    <div className="cart__modal">
      <div className="cart__top--wrapper">
        <img
          src={slideBack}
          alt="slideback"
          className="cart__contact--image"
          width={21}
          height={21}
        />{" "}
        <p className="cart__contact--title">контактні дані</p>
      </div>
      <div className="cart__contact--line"></div>
      <div className="cart__contact--wrapper">
        <p className="cart__contact--data">Ваше замовлення</p>
        <img src={arrow} alt="arrow" width={24} height={24} />
      </div>
      <div
        className="cart__contact--line"
        style={{ marginBottom: "10px" }}
      ></div>
      <p className="cart__contact--total" style={{ marginBottom: "10px" }}>
        Загальна сума:{" "}
        <span className="cart__contact--total cart__total--accent">3499</span>{" "}
        <span className="cart__total--old">3999</span>
        <span className="cart__total--uah" style={{ marginLeft: "-4px" }}>
          ГРН
        </span>
      </p>
      <input
        type="text"
        placeholder="Імʼя"
        className="present__input cart__input"
      />
      <input
        type="text"
        placeholder="+38(___) ___-__-__"
        className="present__input cart__input"
      />
      <div className="cart__contact--wrapper">
        <p className="cart__contact--info">
          Детальна інформація (необов'язково)
        </p>
        <img src={arrow} alt="arrow" width={24} height={24} />
      </div>
    </div>
  );
};
