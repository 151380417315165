import { useEffect, useState } from "react";
import { sendCallback, sendSms } from "../../services/axios";

export const SmsForm = ({
  setIsSmsFormOpen,
  setIsPrizeAvailable,
  setIsSmsSent,
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [time, setTime] = useState(
    localStorage.getItem("timeCounting") ?? 59 * 60 + 59
  );
  const typeOfPrize = localStorage.getItem("wheelPrizeText");

  function isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  const onPhoneChange = (e) => {
    if (e.target.value.trim() === "") {
      setPhoneNumber("380");
    } else if (phoneNumber === "38") {
      setPhoneNumber("380");
    } else if (phoneNumber === "3") {
      setPhoneNumber("380");
    } else {
      const newValue = +e.target.value.trim();
      if (isNumber(newValue)) {
        if (
          e.target.value.length > 0 &&
          isNumber(+e.target.value[e.target.value.length - 1])
        ) {
          setPhoneNumber(e.target.value.trim());
        }
      }
    }
  };

  useEffect(() => {
    if (time > 0) {
      const timerId = setInterval(() => {
        localStorage.setItem("timeCounting", time);
        setTime(time - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [time]);

  const formatTime = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${hrs.toString().padStart(2, "0")}:${mins
      .toString()
      .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
  };
  return (
    <div
      className="backdrop"
      id="backdrop"
      onClick={(e) => {
        if (e.target.id === "backdrop") {
          setIsSmsFormOpen(false);
        }
      }}
    >
      <div className="smsForm__modal">
        <svg
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="smsForm__icon"
          onClick={() => {
            setIsSmsFormOpen(false);
          }}
        >
          <path
            d="M16.5 2.75C24.1038 2.75 30.25 8.89625 30.25 16.5C30.25 24.1038 24.1038 30.25 16.5 30.25C8.89625 30.25 2.75 24.1038 2.75 16.5C2.75 8.89625 8.89625 2.75 16.5 2.75ZM21.4363 9.625L16.5 14.5613L11.5637 9.625L9.625 11.5637L14.5613 16.5L9.625 21.4363L11.5637 23.375L16.5 18.4387L21.4363 23.375L23.375 21.4363L18.4387 16.5L23.375 11.5637L21.4363 9.625Z"
            fill="#929292"
          />
        </svg>
        <p className="smsForm__title">ваш приз</p>
        {typeOfPrize === "1" ? (
          <p className="smsForm__text">Безкоштовна доставка</p>
        ) : typeOfPrize === "2" ? (
          <p className="smsForm__text">
            Додаткові <span className="smsForm__text--accent">-20%</span> на 2
            товар у чеку
          </p>
        ) : (
          <p className="smsForm__text">
            Знижка <span className="smsForm__text--accent">-100грн</span> на
            замовлення
          </p>
        )}
        <div className="smsForm__line"></div>
        <div className="smsForm__wrapper">
          <p className="smsForm__text--left">Тип</p>
          <p className="smsForm__text--right">Одноразовий</p>
        </div>
        <div className="smsForm__wrapper">
          <p className="smsForm__text--left">Закінчується через</p>
          <p className="smsForm__text--timer">{formatTime(time)}</p>
        </div>
        <div className="smsForm__line"></div>
        <p className="smsForm__info">
          Ми надішлемо промокод в смс на ваш номер телефону
        </p>
        <input
          type="text"
          placeholder="38(___) ___-__-__"
          maxLength={13}
          minLength={12}
          className="smsForm__input"
          onChange={onPhoneChange}
          onClick={() => {
            if (phoneNumber === "") {
              setPhoneNumber("380");
            }
          }}
          value={phoneNumber}
        />
        <button
          type="button"
          className="smsForm__button"
          onClick={() => {
            if (phoneNumber.length >= 12) {
              sendSms(
                phoneNumber,
                typeOfPrize === "1"
                  ? "Ваш промокод 3008, активуйте на сайті - https://bit.ly/3V8cuZl"
                  : typeOfPrize === "2"
                  ? "Ваш промокод 7007, активуйте на сайті - https://bit.ly/4aoay33"
                  : "Ваш промокод 6277, активуйте на сайті - https://bit.ly/4dJY6h9"
              );
              sendCallback(
                "0",
                phoneNumber,
                "Отримали бонус",
                typeOfPrize === "1"
                  ? "Отримали бонус - Безкоштовна доставка, промокод 3008, доступний при замовлені від 1399 грн"
                  : typeOfPrize === "2"
                  ? "Отримали бонус - Знижка -20% на другий товар, промокод 7007"
                  : "Отримали бонус - Знижка -100грн, промокод 6277, доступний при замовлені від 1399 грн"
              );
              setTimeout(() => {
                localStorage.setItem("lastClickTime", Date.now().toString());
                setIsPrizeAvailable(false);
                setIsSmsFormOpen(false);
                setIsSmsSent(true);
              }, 1000);
            }
          }}
        >
          Отримати
        </button>
        <p className="smsForm__description">
          {typeOfPrize === "1"
            ? "*Бонус на безкоштовну доставку можна використати при замовлені на суму від 1399 гривень , та надає можливість отримати замовлення без сплати за доставку. Промокод можна використати лише один раз, та не сумується з іншими акціями"
            : typeOfPrize === "2"
            ? "*Бонус можна використати при замовлені від 2-х одиниць товару , та надає додаткову знижку -20% виключно на другий товар у чеку. Промокод можна використати лише один раз, та не сумується з іншими акціями"
            : "*Бонус можна використати при замовлені на суму від 1399 гривень , та надає додаткову знижку у розмірі 100 гривень на замовлення. Промокод можна використати лише один раз, та не сумується з іншими акціями"}
        </p>
      </div>
      <div id="pixelTagIdSms"></div>
    </div>
  );
};
