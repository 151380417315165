import logo from "../../assets/images/logo.webp";

const Preloader = () => {
  return (
    <div className="moon__wrapper">
      <div className="moon">
        <div className="moon__black"></div>
      </div>
      <img src={logo} alt="logo" width={169} height={52} />
    </div>
  );
};

export default Preloader;
