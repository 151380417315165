import { useState } from "react";
import instagram from "../../assets/images/instagram.webp";
import square from "../../assets/images/square.webp";
import cross from "../../assets/images/cross.webp";

export const Instagram = () => {
  const [isTextOpen, setIsTextOpen] = useState(
    !localStorage.getItem("instagramTextClosed")
  );
  const onInstagramButtonClick = () => {
    window.open("https://ig.me/m/princess.wigs", "_blank");
  };
  return (
    <>
      {isTextOpen && (
        <div className="instagram">
          <img src={square} alt="square" className="instagram__square" />
          <p className="instagram__text fade">
            Наш консультант допоможе вам з вибором
          </p>
          <img
            src={cross}
            alt="cross"
            className="instagram__cross"
            onClick={() => {
              setIsTextOpen(false);
              localStorage.setItem("instagramTextClosed", false);
            }}
          />
        </div>
      )}
      <img
        src={instagram}
        alt="instagram"
        className="gift__icon gift__icon--instagram"
        onClick={onInstagramButtonClick}
      />
    </>
  );
};
