import FooterWheel from "../../components/FooterWheel/FooterWheel";
import HeaderWheel from "../../components/HeaderWheel/HeaderWheel";
import { WheelLand } from "../../components/WheelLand/WheelLand";

export const WheelUpdate = ({
  setIsHidden,
  setFrom,
  isMenuOpen,
  setIsMenuOpen,
  id,
  isPrizeAvailable,
  setIsSmsFormOpen,
  setIsWheelModalOpen,
  setIsSmsSent,
}) => {
  return (
    <>
      <HeaderWheel
        setIsHidden={setIsHidden}
        isHidden={true}
        setFrom={setFrom}
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        modelName={"Лендинг колесо"}
        wheelland={true}
      />
      <WheelLand
        isPrizeAvailable={isPrizeAvailable}
        setIsSmsFormOpen={setIsSmsFormOpen}
        setIsWheelModalOpen={setIsWheelModalOpen}
        setIsSmsSent={setIsSmsSent}
      />
      {/* <FooterWheel
        setIsHidden={setIsHidden}
        setFrom={setFrom}
        modelName={"Лендинг колесо"}
        setIsMenuOpen={setIsMenuOpen}
        id={id}
      /> */}
    </>
  );
};
