import { Data } from "./Data/Data";
import oliviya from "../../assets/images/oliviya/10.webp";
import { Contact } from "./Contact/Contact";

export const Cart = () => {
  const cart = [
    {
      image: oliviya,
      name: "Олівія",
      price: "1499 ГРН",
      color: "15BT613",
      quantity: 5,
    },
  ];
  return (
    <div className="present__backdrop">
      {/* <Data cart={cart} /> */}
      <Contact />
    </div>
  );
};
