import { useEffect, useState } from "react";
import { ModelCreate } from "../../components/ModelCreate/ModelCreate";
import { ModelEdit } from "../../components/ModelEdit/ModelEdit";
import {
  getAllModels,
  getModel,
  hideModel,
  tryLogin,
} from "../../services/axios";
import { ToastContainer, toast } from "react-toastify";

export const ModelPanel = () => {
  const [page, setPage] = useState("base");
  const [models, setModels] = useState([]);
  const [model, setModel] = useState(null);

  useEffect(() => {
    const tryGetAccess = async () => {
      const password = localStorage.getItem("passwordToAdminPanel");
      if (password) {
        if ((await tryLogin(password)) === "denied") {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }
    };

    tryGetAccess();
  }, []);

  const notify = () => toast.success("Success!");

  useEffect(() => {
    const fetchModels = async () => {
      const fetchedModels = await getAllModels();

      setModels(fetchedModels);
    };
    fetchModels();
  }, []);

  return (
    <div className="container" style={{ minHeight: "100vh", padding: "0" }}>
      {page === "base" ? (
        <>
          <button
            type="button"
            className="model__panel--button"
            onClick={() => {
              setPage("create");
            }}
          >
            Створити
          </button>
          <div className="panel__list">
            {models?.map(({ _id, id, image, name, show }) => (
              <div className="panel__item--wrapper" key={_id}>
                <div
                  style={
                    show
                      ? { backgroundColor: "#fcb25f" }
                      : {
                          backgroundColor: "rgb(189, 193, 198)",
                          border: "rgb(93, 93, 93)",
                        }
                  }
                  key={_id}
                  className="panel__item"
                  onClick={async () => {
                    setModel(await getModel(_id));
                    setPage("edit");
                  }}
                >
                  <div className="panel__id--wrapper">
                    <p className="panel__id">{id}</p>
                  </div>
                  <img src={image} alt={name} className="panel__image" />
                  <p className="panel__name">{name}</p>
                </div>
                <button
                  className="panel__button--hide"
                  onClick={() => {
                    hideModel(_id, !show);
                    notify();
                  }}
                >
                  {show ? "Active" : "Hidden"}
                </button>
              </div>
            ))}
          </div>
        </>
      ) : page === "create" ? (
        <ModelCreate />
      ) : (
        <ModelEdit model={model} />
      )}
      <ToastContainer />
    </div>
  );
};
