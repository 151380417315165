import { NavLink } from "react-router-dom";
import logo from "../../assets/images/logo.webp";
import { Link } from "react-scroll";

const Menu = ({ setIsMenuOpen, setIsHidden }) => {
  return (
    <div
      className="backdrop__menu"
      onClick={(e) => {
        if (e.target.id !== "modal") {
          setIsMenuOpen(false);
        }
      }}
    >
      <div className="modal__menu" id="modal">
        <NavLink to="/">
          <img src={logo} alt="logo" className="menu__logo" />
        </NavLink>
        <ul className="menu__list">
          <Link
            to="catalog"
            spy={true}
            smooth={true}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            <li className="menu__item wow animate__animated animate__fadeInLeft">
              Каталог
            </li>
          </Link>
          <Link
            to="about"
            spy={true}
            smooth={true}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            <li className="menu__item wow animate__animated animate__fadeInLeft">
              Про нас
            </li>
          </Link>
          <Link
            to="reviews"
            spy={true}
            smooth={true}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            <li className="menu__item wow animate__animated animate__fadeInLeft">
              Відгуки
            </li>
          </Link>
          <Link
            to="delivery"
            spy={true}
            smooth={true}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            <li className="menu__item wow animate__animated animate__fadeInLeft">
              Доставка і оплата
            </li>
          </Link>
          <Link
            to="contacts"
            spy={true}
            smooth={true}
            onClick={() => {
              setIsMenuOpen(false);
            }}
          >
            <li className="menu__item wow animate__animated animate__fadeInLeft">
              Контакти
            </li>
          </Link>
        </ul>
        <button
          type="button"
          className="menu__callback"
          onClick={() => {
            setIsHidden("support");
            setIsMenuOpen(false);
          }}
        >
          Зворотній зв`язок
        </button>
        <NavLink to="https://www.facebook.com">
          <div className="menu__facebook--wrapper">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.397 20.9972V12.8012H16.162L16.573 9.59217H13.397V7.54817C13.397 6.62217 13.655 5.98817 14.984 5.98817H16.668V3.12717C15.8487 3.03936 15.0251 2.99696 14.201 3.00017C11.757 3.00017 10.079 4.49217 10.079 7.23117V9.58617H7.33203V12.7952H10.085V20.9972H13.397Z"
                fill="url(#paint0_linear)"
              ></path>
              <defs>
                <linearGradient
                  id="paint0_linear"
                  x1="12"
                  y1="3"
                  x2="12"
                  y2="20.9972"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#FCB25F"></stop>
                  <stop offset="1" stopColor="#FDE7CE"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default Menu;
