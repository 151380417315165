// import { malva } from "./malva.js";
// import { anita } from "./anita.js";
// import { vladuslava } from "./vladuslava.js";
// import { dayana } from "./dayana.js";
// import { zorina } from "./zorina.js";
// import { bogdana } from "./bogdana.js";
// import { ilona } from "./ilona.js";
// import { liya } from "./liya.js";
// import { gloriya } from "./gloriya.js";
// import { sofiya } from "./sofiya.js";
// import { melissa } from "./melissa.js";
// import { angela } from "./angela.js";
// import { sheri } from "./sheri.js";
// import { roxolana } from "./roxolana.js";
// import { mariya } from "./mariya.js";
// import { adriana } from "./adriana.js";
// import { regina } from "./regina.js";
// import { ruma } from "./ruma.js";
// import { agata } from "./agata.js";
// import { violeta } from "./violeta.js";
// import { arianna } from "./arianna.js";
// import { oliviya } from "./oliviya.js";
// import { yunona } from "./yunona.js";
// import { flora } from "./flora.js";
// import { meri } from "./meri.js";
// import { alisa } from "./alisa.js";
// import { dariya } from "./dariya.js";
// import { reveka } from "./reveka.js";
// import { svitlana } from "./svitlana.js";
// import { olesya } from "./olesya.js";
// import { zlata } from "./zlata.js";
// import { lika } from "./lika.js";
// import { sabina } from "./sabina.js";
// import { sharlotta } from "./sharlotta.js";
// import { alina } from "./alina.js";
// import { viola } from "./viola.js";
// import { evelina } from "./evelina.js";
// import { roksi } from "./roksi.js";
// import { goldi } from "./goldi.js";
// import { marta } from "./marta.js";
// import { anna } from "./anna.js";
// import { natali } from "./natali.js";
// import { xrustuna } from "./xrustuna.js";
// import { amaliya } from "./amaliya.js";
// import { dora } from "./dora.js";
// import { laura } from "./laura.js";
// import { karolina } from "./karolina.js";
// import { tina } from "./tina.js";
// import { valeriya } from "./valeriya.js";
// import { dzhuliya } from "./dzhuliya.js";
// import { xelen } from "./xelen.js";
// import { lili } from "./lili.js";
// import { frida } from "./frida.js";
// import { kamilla } from "./kamilla.js";
// import { bella } from "./bella.js";
// import { myza } from "./myza.js";
// import { monica } from "./monica.js";
// import { nika } from "./nika.js";
// import { mila } from "./mila.js";
// import { polina } from "./polina.js";
// import { diana } from "./diana.js";
// import { elza } from "./elza.js";
// import { kris } from "./kris.js";
// import { ella } from "./ella.js";
// import { elizabet } from "./elizabet.js";
// import { selena } from "./selena.js";
// // import { milana } from "./milana.js";
// import { rozaliya } from "./rozaliya.js";
// import { kira } from "./kira.js";
// import { snizhana } from "./snizhana.js";
// import { nonna } from "./nonna.js";
// import { riana } from "./riana.js";
// import { dina } from "./dina.js";
// import { greta } from "./greta.js";
// import { izabela } from "./izabela.js";
// import { nikol } from "./nikol.js";
// import { adel } from "./adel.js";
// import { lina } from "./lina.js";
// import { elina } from "./elina.js";
// import { ameliya } from "./ameliya.js";
// import { stefaniya } from "./stefaniya.js";
// import { maya } from "./maya.js";
// import { angelika } from "./angelika.js";
// import { nikki } from "./nikki.js";
// import { emma } from "./emma.js";
// import { dzhudi } from "./dzhudi.js";
// import { linda } from "./linda.js";
// import { luiza } from "./luiza.js";
// import { lira } from "./lira.js";
// import { milena } from "./milena.js";
// import { avrora } from "./avrora.js";
// import { melaniya } from "./melaniya.js";
// import { xana } from "./xana.js";
// import { meril } from "./meril.js";
// import { emiliya } from "./emiliya.js";
// import { dzhuli } from "./dzhuli.js";
// import { lana } from "./lana.js";
// import { katrin } from "./katrin.js";
// import { arina } from "./arina.js";
// import { melli } from "./melli.js";
// import { yuliana } from "./yuliana.js";
// import { freya } from "./freya.js";
// import { greys } from "./greys.js";
import { getAllModels } from "../services/axios.js";

export const models = await getAllModels(true);

// export const models = [
//   ...malva,
//   ...anita,
//   ...dayana,
//   ...zorina,
//   ...vladuslava,
//   ...gloriya,
//   ...melissa,
//   ...bogdana,
//   ...sheri,
//   ...adriana,
//   ...liya,
//   ...roxolana,
//   ...mariya,
//   ...regina,
//   ...arianna,
//   ...meril,
//   ...emiliya,
//   ...dzhuli,
//   ...oliviya,
//   ...melli,
//   ...sofiya,
//   ...agata,
//   ...yunona,
//   ...meri,
//   ...flora,
//   ...svitlana,
//   ...olesya,
//   ...ilona,
//   ...yuliana,
//   ...lana,
//   ...zlata,
//   ...viola,
//   // ...katrin,
//   ...angela,
//   ...violeta,
//   ...lika,
//   ...sabina,
//   ...sharlotta,
//   ...alina,
//   ...goldi,
//   ...freya,
//   ...arina,
//   ...marta,
//   ...anna,
//   ...xrustuna,
//   ...ruma,
//   ...alisa,
//   ...dariya,
//   ...reveka,
//   ...natali,
//   ...amaliya,
//   ...dora,
//   ...laura,
//   ...karolina,
//   ...tina,
//   ...valeriya,
//   ...greys,
//   ...xelen,
//   ...evelina,
//   ...roksi,
//   ...dzhuliya,
//   ...lili,
//   ...kamilla,
//   ...bella,
//   ...myza,
//   ...monica,
//   ...frida,
//   ...nika,
//   ...mila,
//   ...polina,
//   ...diana,
//   ...elza,
//   ...kris,
//   ...ella,
//   ...elizabet,
//   ...selena,
//   // ...milana,
//   ...rozaliya,
//   ...kira,
//   ...snizhana,
//   ...nonna,
//   ...riana,
//   ...dina,
//   ...greta,
//   ...izabela,
//   ...nikol,
//   ...adel,
//   ...lina,
//   ...elina,
//   ...ameliya,
//   ...stefaniya,
//   ...maya,
//   ...angelika,
//   ...nikki,
//   ...emma,
//   ...dzhudi,
//   ...linda,
//   ...luiza,
//   ...lira,
//   ...milena,
//   ...avrora,
//   ...melaniya,
//   ...xana,
// ];
