import { useState } from "react";
import { getFilter, updateFilter } from "../../services/axios";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

export const FilterPanel = () => {
  const [types, setTypes] = useState("");
  const [materials, setMaterials] = useState("");
  const [lengths, setLengths] = useState("");
  // const [colors, setColors] = useState("");
  const [_id, setId] = useState("");

  const notify = () => toast.success("Success!");

  useEffect(() => {
    const fetchFilter = async () => {
      const data = await getFilter();
      setId(data._id);
      setTypes(data.types.join(","));
      setMaterials(data.materials.join(","));
      setLengths(data.lengths.join(","));
      // setColors(data.colors.join(","));
    };

    fetchFilter();
  }, []);

  const onFilterSave = () => {
    const data = { _id };
    data.types = types.split(",");
    data.materials = materials.split(",");
    data.lengths = lengths.split(",");
    // data.colors = colors.split(",");

    updateFilter(data);
  };
  return (
    <div className="container" style={{ minHeight: "100vh", padding: "0" }}>
      <div className="filter__panel">
        <div className="filter__panel--wrapper">
          <p className="filter__panel--text">Тип зачіски</p>
          <input
            type="text"
            className="model__panel--input"
            style={{ width: "100%" }}
            value={types}
            onChange={(e) => {
              setTypes(e.target.value);
            }}
          />
        </div>
        <div className="filter__panel--wrapper">
          <p className="filter__panel--text">Матеріал</p>
          <input
            type="text"
            className="model__panel--input"
            style={{ width: "100%" }}
            value={materials}
            onChange={(e) => {
              setMaterials(e.target.value);
            }}
          />
        </div>
        <div className="filter__panel--wrapper">
          <p className="filter__panel--text">Довжина</p>
          <input
            type="text"
            className="model__panel--input"
            style={{ width: "100%" }}
            value={lengths}
            onChange={(e) => {
              setLengths(e.target.value);
            }}
          />
        </div>
        {/* <div className="filter__panel--wrapper">
          <p className="filter__panel--text">Колір</p>
          <input
            type="text"
            className="model__panel--input"
            style={{ width: "100%" }}
            value={colors}
            onChange={(e) => {
              setColors(e.target.value);
            }}
          />
        </div> */}
        <button
          style={{ margin: "0 auto" }}
          type="button"
          className="model__panel--button"
          onClick={() => {
            onFilterSave();
            notify();
          }}
        >
          Save
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};
