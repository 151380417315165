let pixel = localStorage.getItem("pixel");
let fbclid = localStorage.getItem("fbclid");
let utm_source = localStorage.getItem("utm_source");
let utm_medium = localStorage.getItem("utm_medium");
let utm_term = localStorage.getItem("utm_term");
let utm_content = localStorage.getItem("utm_content");
let utm_campaign = localStorage.getItem("utm_campaign");

if (
  utm_source === "null" ||
  utm_source === "" ||
  utm_source === null ||
  utm_source === undefined
) {
  utm_source = "smk";
}

const link =
  `https://axioma.bot/api/ext/652c43b8564d368b7c0640c6/66d6f0013f99e9f7c6beffc6/652c43ee564d368b7c0642a8/652fa318660a0066963644c5/invite?utm_source=${utm_source}` +
  (pixel !== null ? `&pixel=${pixel}` : "") +
  (fbclid !== null ? `&fbclid=${fbclid}` : "") +
  (utm_medium !== null ? `&utm_medium=${utm_medium}` : "") +
  (utm_term !== null ? `&utm_term=${utm_term}` : "") +
  (utm_content !== null ? `&utm_content=${utm_content}` : "") +
  (utm_campaign !== null ? `&utm_campaign=${utm_campaign}` : "");

const fallbackLink = "tg://join?invite=HhiTUW5R3IlkZTcy";
let linkToOpen;

fetch(link)
  .then((r) => r.json())
  .then(({ inviteLink }) => {
    let uniqueIdentifier = inviteLink.replace("https://t.me/+", "");
    let deepLink = "tg://join?invite=" + uniqueIdentifier;
    linkToOpen = deepLink || fallbackLink;
  })
  .catch(() => {
    linkToOpen = fallbackLink;
  });

export const addLinkToButton = () => {
  window.location.href = linkToOpen;
};
