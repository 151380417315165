import present from "../../assets/images/present.png";
import present1 from "../../assets/images/present1.png";

export const Prize = ({
  isPrizeAvailable,
  setIsWheelModalOpen,
  setIsSmsFormOpen,
  setIsSmsSent,
}) => {
  return (
    <>
      {isPrizeAvailable ? (
        <img
          src={present1}
          alt="Gift"
          className="gift__icon gift__animation"
          onClick={() => {
            if (localStorage.getItem("isWheelAlreadyRunned")) {
              setIsSmsFormOpen(true);
            } else {
              localStorage.setItem("wheelPopupByTimer", true);
              setIsWheelModalOpen(true);
            }
          }}
        />
      ) : (
        <img
          src={present}
          alt="Gift"
          className="gift__icon"
          onClick={() => {
            setIsSmsSent(true);
          }}
        />
      )}
    </>
  );
};
