import { NavLink } from "react-router-dom";

export const HeaderPanel = () => {
  return (
    <header className="header__panel container" style={{ padding: "0" }}>
      <NavLink to="/smk147uoljowb/model" className="header__tab">
        Model
      </NavLink>
      <NavLink to="/smk147uoljowb/new" className="header__tab">
        New Section
      </NavLink>
      <NavLink to="/smk147uoljowb/filter" className="header__tab">
        Filter
      </NavLink>
    </header>
  );
};
