import { addLinkToButton } from "../../telegram/telegram";

export const Telegram = ({ setIsTelegramPopupOpen }) => {
  return (
    <div className="telegram__backdrop">
      <div className="telegram__modal">
        <svg
          onClick={() => {
            setIsTelegramPopupOpen(false);
          }}
          className="telegram__icon"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 2.75C24.1038 2.75 30.25 8.89625 30.25 16.5C30.25 24.1038 24.1038 30.25 16.5 30.25C8.89625 30.25 2.75 24.1038 2.75 16.5C2.75 8.89625 8.89625 2.75 16.5 2.75ZM21.4363 9.625L16.5 14.5613L11.5637 9.625L9.625 11.5637L14.5613 16.5L9.625 21.4363L11.5637 23.375L16.5 18.4387L21.4363 23.375L23.375 21.4363L18.4387 16.5L23.375 11.5637L21.4363 9.625Z"
            fill="#ffffff"
          />
        </svg>
        <p className="telegram__title">підписуйтесь</p>
        <p className="telegram__description">
          на наш телеграм канал та економте до
        </p>
        <p className="telegram__accent">70%</p>
        <button
          type="button"
          className="telegram__button"
          onClick={() => {
            addLinkToButton();
          }}
        >
          Підписатись
        </button>
        <p
          className="telegram__declain"
          onClick={() => {
            setIsTelegramPopupOpen(false);
          }}
        >
          Нагадайте пізніше
        </p>
      </div>
    </div>
  );
};
