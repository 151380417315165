import { useState } from "react";
import present from "../../assets/images/present.webp";
import { sendCallback } from "../../services/axios";

export const Present = ({ setIsPresentPopupOpen, from, id }) => {
  const regex = /^Консультація на\s+/;
  from = from.replace(regex, "");

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [nameError, setNameError] = useState(false);

  function isNumber(value) {
    return typeof value === "number" && !isNaN(value);
  }

  const onNameChange = (e) => {
    setName(e.target.value);
  };

  const onPhoneChange = (e) => {
    if (e.target.value.trim() === "") {
      setPhoneNumber("");
    } else {
      const newValue = +e.target.value.trim();
      if (isNumber(newValue)) {
        if (
          e.target.value.length > 0 &&
          isNumber(+e.target.value[e.target.value.length - 1])
        ) {
          setPhoneNumber(e.target.value.trim());
        }
      }
    }
  };
  return (
    <div className="present__backdrop">
      <div className="present__modal">
        <svg
          onClick={() => {
            setIsPresentPopupOpen(false);
          }}
          className="present__icon"
          width="33"
          height="33"
          viewBox="0 0 33 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 2.75C24.1038 2.75 30.25 8.89625 30.25 16.5C30.25 24.1038 24.1038 30.25 16.5 30.25C8.89625 30.25 2.75 24.1038 2.75 16.5C2.75 8.89625 8.89625 2.75 16.5 2.75ZM21.4363 9.625L16.5 14.5613L11.5637 9.625L9.625 11.5637L14.5613 16.5L9.625 21.4363L11.5637 23.375L16.5 18.4387L21.4363 23.375L23.375 21.4363L18.4387 16.5L23.375 11.5637L21.4363 9.625Z"
            fill="#292D34"
          />
        </svg>
        <p className="present__title">замовляйте</p>
        <p className="present__description">
          зараз та отримайте подарунковий сертифікат
        </p>
        <img src={present} alt="Подарунок" className="present__image" />
        <div className="input__error--wrapper">
          {nameError && <p className="error__validation">Введіть імʼя</p>}
          <input
            type="text"
            className="present__input"
            placeholder="Ім`я"
            onChange={onNameChange}
            value={name}
            style={{
              border: nameError ? "1px solid red" : "1px solid #292D34",
            }}
          />
        </div>
        <div className="input__error--wrapper">
          {phoneError && (
            <p className="error__validation">
              Введіть коректний номер телефону
            </p>
          )}
          <input
            type="text"
            className="present__input"
            placeholder="38(___)___-__-__"
            onChange={onPhoneChange}
            value={phoneNumber}
            maxLength={13}
            minLength={10}
            style={{
              border: phoneError ? "1px solid red" : "1px solid #292D34",
            }}
          />
        </div>
        <button
          type="button"
          className="present__button"
          onClick={() => {
            if (phoneNumber.length >= 10 && phoneNumber.length <= 13 && name) {
              sendCallback(
                "0",
                phoneNumber,
                name,
                `Оформити замовлення на ${from} + подарунковий сертифікат`,
                id
              );
              window.location.href = "/thanks";
            } else {
              if (!name) {
                setNameError(true);
              } else {
                setNameError(false);
              }

              if (phoneNumber.length <= 10 || phoneNumber.length >= 13) {
                setPhoneError(true);
              } else {
                setPhoneError(false);
              }
            }
          }}
        >
          Замовити
        </button>
        <p className="present__text">
          Ваші данні не будуть передаватися третім лицям
        </p>
      </div>
    </div>
  );
};
